<template>
  <div class="payInfos">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <el-form
          ref="form"
          :model="form"
          label-width="80px"
          style="display: inline-block; float: left"
        >
          <el-form-item label="用户名:">
            <el-input
              style="margin-right: 10px; width: 200px"
              v-model="form.unit"
            ></el-input>
          </el-form-item>
        </el-form>

        <el-button
          style="float: left"
          type="primary"
          plain
          @click="searchSchedule"
          >搜索</el-button
        >
        <el-button style="float: left" type="primary" plain @click="exporting"
          >导出</el-button
        >
      </div>
      <div class="main-card">
        <el-table
          :data="tableData"
          style="width: 100%"
          height="550"
          :header-cell-style="{ textAlign: 'center' }"
          :cell-style="{ textAlign: 'center' }"
        >
          <el-table-column label="用户名">
            <template slot-scope="scope">
              <span>{{ scope.row.username }}</span>
            </template>
          </el-table-column>
          <el-table-column label="电话" width="180">
            <template slot-scope="scope">
              <span>{{ scope.row.mobileNo }}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column label="邮箱" width="180">
            <template slot-scope="scope">
              <span>{{ scope.row.surplus }}</span>
            </template>
          </el-table-column>
          <el-table-column label="门票">
            <template slot-scope="scope">
              <span>{{ scope.row.discount }}</span>
            </template>
          </el-table-column> -->
          <el-table-column label="单价">
            <template slot-scope="scope">
              <span>{{ scope.row.cost / 100 }}</span>
            </template>
          </el-table-column>
          <el-table-column label="数量">
            <template slot-scope="scope">
              <span>{{ scope.row.ticketNum }}</span>
            </template>
          </el-table-column>
          <el-table-column label="已折金额">
            <template slot-scope="scope">
              <span>{{ scope.row.discountMoney / 100 }}</span>
            </template>
          </el-table-column>
          <el-table-column label="实付金额">
            <template slot-scope="scope">
              <span>{{ scope.row.payMoney / 100 }}</span>
            </template>
          </el-table-column>
          <el-table-column label="购买日期">
            <template slot-scope="scope">
              <span>{{ scope.row.payTime }}</span>
            </template>
          </el-table-column>
          <el-table-column label="支付单号">
            <template slot-scope="scope">
              <span>{{ scope.row.payOrder }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="page-card">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: this.$route.params.id,
      tableData: [
        {
          name: "门票1",
          price: 1,
          numIssues: 12,
          surplus: 10,
          discount: "参与",
        },
      ],
      form: {
        unit: "",
      },
      unitList: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
    };
  },
  created() {
    this.getTableData();
    // this.getUnitDrop()
  },
  methods: {
    //获取所有门票支付信息
    async getTableData() {
      try {
        const result = await this.$api.getPayInfo(
          `currentPage=${this.currentPage}&pageSize=${this.pageSize}&search_LIKE_username=${this.form.unit}&search_EQ_competitionId=${this.id}`
        );
        this.tableData = result.pageResults;
        this.total = result.totalCount;
        console.log(result);
      } catch (error) {
        console.log(error);
      }
    },
    //查新支付信息
    async searchSchedule() {
      this.currentPage = 1;
      this.pageSize = 10;
      try {
        const result = await this.$api.getPayInfo(
          `currentPage=${this.currentPage}&pageSize=${this.pageSize}&search_LIKE_username=${this.form.unit}&search_EQ_competitionId=${this.id}`
        );
        this.tableData = result.pageResults;
        this.total = result.totalCount;
        console.log(result);
      } catch (error) {
        console.log(error);
      }
    },
    async getUnitDrop() {
      try {
        const result = await this.$api.unitDrop();
        this.unitList = result;
        console.log(result);
      } catch (error) {
        console.log(error);
      }
    },
    //导出支付信息
    async exporting() {
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      try {
        const result = await this.$api.exportTicketPayInfo(
          `search_EQ_competitionId=${this.id}`
        );
        console.log(result);
        let content = result.headers["content-disposition"].split(";"); // 从响应头中拿到文件名
        let fileName = content[1].split("=")[1]; // 从响应头中拿到文件名
        console.log(decodeURI(fileName), result);
        if (result.data) {
          let blob = new Blob([result.data], {
            type: "application/vnd.ms-excel",
          });
          let objectUrl = URL.createObjectURL(blob); //创建URL
          a.href = objectUrl;
          a.download = decodeURI(fileName);
          a.click();
          URL.revokeObjectURL(objectUrl); // 释放内存
          setTimeout(() => {
            document.body.removeChild(a);
          }, 2000);
        }
      } catch (error) {
        console.log(error);
      }
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getTableData();
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getTableData();
      console.log(`当前页: ${val}`);
    },
  },
};
</script>

<style lang="less">
.payInfos {
  .text {
    font-size: 14px;
  }

  .item {
    margin-bottom: 18px;
  }

  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }
  .clearfix:after {
    clear: both;
  }

  .box-card {
    width: 100%;
  }
  .el-dialog__footer {
    text-align: center !important;
  }
  .el-dialog__header {
    text-align: center;
  }
  .page-card {
    margin: 30px 0;
    float: right;
  }
}
</style>
