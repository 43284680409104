<template>
  <div class="ticket">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <el-button
          style="float: left; padding: 3px 0"
          type="text"
          @click="addTicket"
          >新增门票</el-button
        >
      </div>
      <div class="main-card">
        <el-table
          :data="tableData"
          style="width: 100%"
          height="550"
          :header-cell-style="{ textAlign: 'center' }"
          :cell-style="{ textAlign: 'center' }"
        >
          <el-table-column label="门票信息">
            <template slot-scope="scope">
              <span>{{ scope.row.name }}</span>
            </template>
          </el-table-column>
          <el-table-column label="场次名称">
            <template slot-scope="scope">
              <span>{{ scope.row.sceneName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="门票日期">
            <template slot-scope="scope">
              <span>{{ $moment(scope.row.time).format("YYYY-MM-DD") }}</span>
            </template>
          </el-table-column>
          <el-table-column label="时间段">
            <template slot-scope="scope">
              <span>{{ scope.row.startHour }}~{{ scope.row.endHour }}</span>
            </template>
          </el-table-column>
          <el-table-column label="单价">
            <template slot-scope="scope">
              <span>{{ scope.row.price / 100 }}</span>
            </template>
          </el-table-column>
          <el-table-column label="发行数量">
            <template slot-scope="scope">
              <span>{{ scope.row.count }}</span>
            </template>
          </el-table-column>
          <el-table-column label="剩余数量">
            <template slot-scope="scope">
              <span>{{ scope.row.remainCount }}</span>
            </template>
          </el-table-column>
          <el-table-column label="参与折扣">
            <template slot-scope="scope">
              <span>{{
                scope.row.useDiscount != null
                  ? options[scope.row.useDiscount].label
                  : scope.row.useDiscount
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="280">
            <template slot-scope="scope">
              <el-button size="mini" @click="handleAdd(scope.$index, scope.row)"
                >增加发行量</el-button
              >
              <el-button
                size="mini"
                @click="handleEdit(scope.$index, scope.row)"
                >编辑</el-button
              >
              <el-button
                size="mini"
                type="danger"
                @click="handleDelete(scope.$index, scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>
    <el-dialog
      title="增加发行数量"
      :visible.sync="dialogVisibleNum"
      width="30%"
      @close="dialogClose"
    >
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item
          label="增加发行数量:"
          :label-width="formLabelWidth"
          prop="increaseCount"
        >
          <el-input v-model="form.increaseCount" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="pushNum">提交</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="修改门票"
      :visible.sync="dialogVisibleEdit"
      width="30%"
      :close-on-click-modal="false"
    >
      <el-form :model="tableDataEdit" :rules="rules" ref="tableDataEdit">
        <el-form-item label="名称:" :label-width="formLabelWidth">
          <el-input v-model="tableDataEdit.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="单价:" :label-width="formLabelWidth">
          <el-input v-model="tableDataEdit.price" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="发行数量:" :label-width="formLabelWidth">
          <el-input v-model="tableDataEdit.count" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="场次名称:" :label-width="formLabelWidth">
          <el-input
            v-model="tableDataEdit.sceneName"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="门票时间:" :label-width="formLabelWidth">
          <el-col :span="12">
            <el-date-picker
              type="date"
              placeholder="选择日期"
              v-model="tableDataEdit.time"
              style="width: 100%"
              format="yyyy-MM-dd 00:00:00"
              value-format="yyyy-MM-dd 00:00:00"
            ></el-date-picker>
          </el-col>
        </el-form-item>
        <el-form-item label="开始时间:" :label-width="formLabelWidth">
          <el-col :span="12">
            <el-time-picker
              v-model="tableDataEdit.startHour"
              placeholder="选择时间"
              format="HH:mm"
              value-format="HH:mm"
            >
            </el-time-picker>
          </el-col>
        </el-form-item>
        <el-form-item label="结束时间:" :label-width="formLabelWidth">
          <el-col :span="12">
            <el-time-picker
              v-model="tableDataEdit.endHour"
              placeholder="选择时间"
              format="HH:mm"
              value-format="HH:mm"
            >
            </el-time-picker>
          </el-col>
        </el-form-item>
        <el-form-item label="参与折扣:" :label-width="formLabelWidth">
          <el-select v-model="tableDataEdit.useDiscount" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="pushEdit">提交</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="添加门票"
      :visible.sync="dialogVisibleAdd"
      width="30%"
      @close="closeDialog"
      :close-on-click-modal="false"
    >
      <el-form :model="tableDataAdd" :rules="rules" ref="tableDataAdd">
        <el-form-item label="名称:" :label-width="formLabelWidth" prop="name">
          <el-input v-model="tableDataAdd.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="单价:" :label-width="formLabelWidth" prop="price">
          <el-input v-model="tableDataAdd.price" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          label="发行数量:"
          :label-width="formLabelWidth"
          prop="count"
        >
          <el-input v-model="tableDataAdd.count" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          label="场次名称:"
          :label-width="formLabelWidth"
          prop="sceneName"
        >
          <el-input
            v-model="tableDataAdd.sceneName"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="门票时间:"
          :label-width="formLabelWidth"
          prop="time"
        >
          <el-col :span="12">
            <el-date-picker
              type="date"
              placeholder="选择日期"
              v-model="tableDataAdd.time"
              style="width: 100%"
              format="yyyy-MM-dd 00:00:00"
              value-format="yyyy-MM-dd 00:00:00"
            ></el-date-picker>
          </el-col>
        </el-form-item>
        <el-form-item
          label="开始时间:"
          :label-width="formLabelWidth"
          prop="startHour"
        >
          <el-col :span="12">
            <el-time-picker
              v-model="tableDataAdd.startHour"
              placeholder="选择时间"
              format="HH:mm"
              value-format="HH:mm"
            >
            </el-time-picker>
          </el-col>
        </el-form-item>
        <el-form-item
          label="结束时间:"
          :label-width="formLabelWidth"
          prop="endHour"
        >
          <el-col :span="12">
            <el-time-picker
              v-model="tableDataAdd.endHour"
              placeholder="选择时间"
              format="HH:mm"
              value-format="HH:mm"
            >
            </el-time-picker>
          </el-col>
        </el-form-item>
        <el-form-item
          label="参与折扣:"
          :label-width="formLabelWidth"
          prop="useDiscount"
        >
          <el-select v-model="tableDataAdd.useDiscount" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="pushAddTicket">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ticketId: this.$route.params.id,
      tableData: [],
      options: [
        {
          value: "0",
          label: "不参与",
        },
        {
          value: "1",
          label: "参与",
        },
      ],
      tableDataAdd: {},
      form: {},
      tableDataEdit: {},
      dialogVisibleNum: false,
      dialogVisibleEdit: false,
      dialogVisibleAdd: false,
      formLabelWidth: "120px",
      row: {},
      value: "",
      rules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        price: [{ required: true, message: "请输入单价", trigger: "blur" }],
        count: [{ required: true, message: "请输入发行数量", trigger: "blur" }],
        sceneName: [
          { required: true, message: "请输入场次名称", trigger: "blur" },
        ],
        time: [{ required: true, message: "请输入门票时间", trigger: "blur" }],
        startHour: [
          { required: true, message: "请输入开始时间", trigger: "blur" },
        ],
        endHour: [
          { required: true, message: "请输入结束时间", trigger: "blur" },
        ],
        useDiscount: [
          { required: true, message: "请选择是否参与折扣", trigger: "blur" },
        ],
        increaseCount: [
          { required: true, message: "请输入数量", trigger: "blur" },
        ],
      },
    };
  },
  props: ["id"],
  created() {
    this.getTableData();
  },
  methods: {
    closeDialog() {
      this.tableDataAdd = {};
    },
    dialogClose() {
      this.form = {};
    },
    handleAdd(index, row) {
      console.log(index, row);
      this.dialogVisibleNum = true;
      this.form.ticketId = row.id;
    },
    handleEdit(index, row) {
      console.log(index, row);
      this.dialogVisibleEdit = true;
      this.tableDataEdit = JSON.parse(JSON.stringify(row));
      this.tableDataEdit.competitionId = this.ticketId;
      this.tableDataEdit.price /= 100;
      if (this.tableDataEdit.useDiscount == 1) {
        this.tableDataEdit.useDiscount = "参与";
      } else if (this.tableDataEdit.useDiscount == 0) {
        this.tableDataEdit.useDiscount = "不参与";
      }
      delete this.tableDataEdit.createTime;
      delete this.tableDataEdit.updateTime;
    },
    handleDelete(index, row) {
      this.$confirm("此操作将永久删除该行, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
      }).then(async () => {
        console.log(index, row);
        try {
          const result = await this.$api.deleteTicket(`ticketId=${row.id}`);
          if (result.data.status_code == "200") {
            this.$message({
              type: "success",
              message: "删除成功",
            });
            this.getTableData();
          } else if (result.data.status_code == "1002") {
            this.$message({
              type: "warning",
              message: result.data.msg,
            });
          }
          console.log(result);
        } catch (error) {
          console.log(error);
        }
      });
    },
    addTicket() {
      this.dialogVisibleAdd = true;
      this.tableDataAdd.competitionId = this.id;
    },
    async getTableData() {
      try {
        const result = await this.$api.getTicketData(
          `competitionId=${this.id}`
        );
        this.tableData = result;
        console.log(result);
      } catch (error) {
        console.log(error);
      }
    },
    pushNum() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.dialogVisibleNum = false;
          try {
            const result = await this.$api.addTicketNum(
              `ticketId=${this.form.ticketId}&increaseCount=${this.form.increaseCount}`
            );
            if (result.data.status_code == "200") {
              this.$message({
                type: "success",
                message: "添加成功",
              });
              this.getTableData();
            } else if (result.data.status_code == "1002") {
              this.$message({
                type: "warning",
                message: result.data.msg,
              });
            }
            console.log(result);
          } catch (error) {
            console.log(error);
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    pushEdit() {
      this.$refs.tableDataEdit.validate(async (valid) => {
        if (valid) {
          this.dialogVisibleEdit = false;
          this.tableDataEdit.price = this.tableDataEdit.price * 100;
          if (this.tableDataEdit.useDiscount == "参与") {
            this.tableDataEdit.useDiscount = 1;
          } else if (this.tableDataEdit.useDiscount == "不参与") {
            this.tableDataEdit.useDiscount = 0;
          }
          try {
            const result = await this.$api.editTicket(this.tableDataEdit);
            if (result.data.status_code == "200") {
              this.$message({
                type: "success",
                message: "修改成功",
              });
              this.getTableData();
            } else if (result.data.status_code == "1002") {
              this.$message({
                type: "warning",
                message: result.data.msg,
              });
            }
            console.log(result);
          } catch (error) {
            console.log(error);
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    pushAddTicket() {
      this.$refs.tableDataAdd.validate(async (valid) => {
        if (valid) {
          this.dialogVisibleAdd = false;
          this.tableDataAdd.price *= 100;
          try {
            const result = await this.$api.addTicket(this.tableDataAdd);
            if (result.data.status_code == "200") {
              this.$message({
                type: "success",
                message: "添加成功",
              });
              this.getTableData();
            } else if (result.data.status_code == "1002") {
              this.$message({
                type: "warning",
                message: result.data.msg,
              });
            }
            console.log(result);
          } catch (error) {
            console.log(error);
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less">
.ticket {
  .text {
    font-size: 14px;
  }

  .item {
    margin-bottom: 18px;
  }

  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }
  .clearfix:after {
    clear: both;
  }

  .box-card {
    width: 100%;
  }
  .el-dialog__footer {
    text-align: center !important;
  }
  .el-dialog__header {
    text-align: center;
  }
}
</style>
