<template>
  <div class="AdmissionTicket">
    <el-page-header @back="goBack" :content="nav" title=""> </el-page-header>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="门票" name="1">
        <Ticket :id="ticketId"></Ticket>
      </el-tab-pane>

      <el-tab-pane label="支付信息" name="2">
        <Payinfo v-if="tab.name == '2'"></Payinfo>
      </el-tab-pane>

      <el-tab-pane label="门票折扣" name="3">
        <Discount v-if="tab.name == '3'"></Discount>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import Ticket from '../../components/ticket.vue'
import Payinfo from '../../components/payInfos.vue'
import Discount from '../../components/discount.vue'
export default {
  data() {
    return {
      nav: this.$route.params.back,
      ticketId: this.$route.params.id,
      activeName: '1',
      tab: {},
      name: '',
    }
  },
  components: {
    Ticket,
    Payinfo,
    Discount,
  },
  mounted() {
    this.updateType()
  },
  methods: {
    updateType() {
      let type = this.$route.params.name
      //通过拿到的值不同，更改activeName的值
      if (type == '1') {
        this.tab.name = '1'
        this.activeName = '1'
      } else if (type == '2') {
        this.tab.name = '2'
        this.activeName = '2'
      } else if (type == '3') {
        this.tab.name = '3'
        this.activeName = '3'
      }
    },
    goBack() {
      this.$router.push({ name: 'Home' })
    },
    handleClick(tab, event) {
      console.log(tab, event)
      this.tab = tab
      if (tab.name == '1') {
        this.name = 1
      } else if (tab.name == '2') {
        this.name = 2
      } else if (tab.name == '3') {
        this.name = 3
      }
      this.$router.push({
        name: 'AdmissionTicket',
        params: { back: this.nav, id: this.ticketId, name: this.name,pageSize:10,currentPage:1 },
      })
    },
  },
}
</script>

<style lang="less">
.AdmissionTicket {
  .text {
    font-size: 14px;
  }

  .item {
    margin-bottom: 18px;
  }

  .clearfix:before,
  .clearfix:after {
    display: table;
    content: '';
  }
  .clearfix:after {
    clear: both;
  }

  .box-card {
    width: 100%;
  }
  .el-dialog__footer {
    text-align: center !important;
  }
  .el-dialog__header {
    text-align: center;
  }
}
</style>
