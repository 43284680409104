<template>
  <div class="discount">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <el-button
          style="float: left; padding: 3px 0"
          type="text"
          @click="addDiscount"
          >新增折扣</el-button
        >
      </div>
      <div class="main-card">
        <el-table
          :data="tableData"
          style="width: 100%"
          height="550"
          :header-cell-style="{ textAlign: 'center' }"
          :cell-style="{ textAlign: 'center' }"
        >
          <el-table-column label="序号">
            <template slot-scope="scope">
              <span>{{ scope.row.id }}</span>
            </template>
          </el-table-column>
          <el-table-column label="创建日期">
            <template slot-scope="scope">
              <span>{{ scope.row.createTime }}</span>
            </template>
          </el-table-column>
          <el-table-column label="最小张数">
            <template slot-scope="scope">
              <span>{{ scope.row.minNumber }}</span>
            </template>
          </el-table-column>
          <el-table-column label="最大张数">
            <template slot-scope="scope">
              <span>{{ scope.row.maxNumber }}</span>
            </template>
          </el-table-column>
          <el-table-column label="折扣率">
            <template slot-scope="scope">
              <span>{{ scope.row.discountRate }}折</span>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button
                size="mini"
                @click="handleEdit(scope.$index, scope.row)"
                >编辑</el-button
              >
              <el-button
                size="mini"
                type="danger"
                @click="handleDelete(scope.$index, scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>

    <el-dialog
      title="编辑折扣"
      :visible.sync="dialogVisibleEdit"
      width="30%"
      :close-on-click-modal="false"
    >
      <el-form :model="editForm" :rules="rules" ref="editForm">
        <div style="display: flex">
          <el-form-item
            label="购买数量:"
            :label-width="formLabelWidth"
            prop="minNumber"
          >
            <el-input
              v-model="editForm.minNumber"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-col class="line" :span="1">~</el-col>
          <el-form-item label-width="0px" prop="maxNumber">
            <el-input
              v-model="editForm.maxNumber"
              autocomplete="off"
            ></el-input>
          </el-form-item>
        </div>

        <el-form-item
          label="折扣率:"
          :label-width="formLabelWidth"
          prop="discountRate"
        >
          <el-input
            v-model="editForm.discountRate"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="pushEditDis">提交</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="添加折扣"
      :visible.sync="dialogVisibleAdd"
      width="30%"
      style="min-width: 300px"
      @close="dialogClose"
      :close-on-click-modal="false"
    >
      <el-form :model="tableDataAdd" :rules="rules" ref="tableDataAdd">
        <div style="display: flex">
          <el-form-item
            label="购买数量:"
            :label-width="formLabelWidth"
            prop="minNumber"
          >
            <el-input
              v-model="tableDataAdd.minNumber"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-col class="line" :span="1">~</el-col>
          <el-form-item label-width="0px" prop="maxNumber">
            <el-input
              v-model="tableDataAdd.maxNumber"
              autocomplete="off"
            ></el-input>
          </el-form-item>
        </div>

        <el-form-item
          label="折扣率:"
          :label-width="formLabelWidth"
          prop="discountRate"
        >
          <el-input
            v-model="tableDataAdd.discountRate"
            autocomplete="off"
            placeholder="例:如打85折，请输入85，非0.85"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="pushAddDis">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: this.$route.params.id,
      tableData: [],
      tableDataAdd: {},
      editForm: {},
      dialogVisibleEdit: false,
      dialogVisibleAdd: false,
      formLabelWidth: "100px",
      row: {},
      value: "",
      rules: {
        minNumber: [
          { required: true, message: "请输入最小数量", trigger: "blur" },
        ],
        maxNumber: [
          { required: true, message: "请输入最大数量", trigger: "blur" },
        ],
        discountRate: [
          { required: true, message: "请输入折扣率", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.getTableData();
  },
  methods: {
    dialogClose() {
      this.tableDataAdd = {};
    },
    handleEdit(index, row) {
      console.log(index, row);
      this.dialogVisibleEdit = true;
      this.editForm = JSON.parse(JSON.stringify(row));
      delete this.editForm.createTime;
      delete this.editForm.updateTime;
    },
    handleDelete(index, row) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        try {
          const result = await this.$api.deleteTicketDisData(`id=${row.id}`);
          if (result.data.status_code == "200") {
            this.$message({
              type: "success",
              message: "删除成功",
            });
            this.getTableData();
          } else if (result.data.status_code == "1002") {
            this.$message({
              type: "warning",
              message: result.data.msg,
            });
          }
          console.log(result);
        } catch (error) {
          console.log(error);
        }
      });
    },
    addDiscount() {
      this.dialogVisibleAdd = true;
      this.tableDataAdd.competitionId = this.id;
      this.tableDataAdd.sort = this.id;
    },
    async getTableData() {
      try {
        const result = await this.$api.getTicketDisData(
          `competitionId=${this.id}`
        );
        this.tableData = result;
        console.log(result);
      } catch (error) {
        console.log(error);
      }
    },
    pushEditDis() {
      this.$refs.editForm.validate(async (valid) => {
        if (valid) {
          this.dialogVisibleEdit = false;
          try {
            const result = await this.$api.addTicketDis(this.editForm);
            if (result.data.status_code == "200") {
              this.$message({
                type: "success",
                message: "修改成功",
              });
              this.getTableData();
            } else if (result.data.status_code == "1002") {
              this.$message({
                type: "warning",
                message: result.data.msg,
              });
            }
            console.log(result);
          } catch (error) {
            console.log(error);
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    pushAddDis() {
      this.$refs.tableDataAdd.validate(async (valid) => {
        if (valid) {
          this.dialogVisibleAdd = false;
          try {
            const result = await this.$api.addTicketDis(this.tableDataAdd);
            if (result.data.status_code == "200") {
              this.$message({
                type: "success",
                message: "添加成功",
              });
              this.getTableData();
            } else if (result.data.status_code == "1002") {
              this.$message({
                type: "warning",
                message: result.data.msg,
              });
            }
            console.log(result);
          } catch (error) {
            console.log(error);
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less">
.discount {
  .text {
    font-size: 14px;
  }

  .item {
    margin-bottom: 18px;
  }

  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }
  .clearfix:after {
    clear: both;
  }

  .box-card {
    width: 100%;
  }
  .el-dialog__footer {
    text-align: center !important;
  }
  .el-dialog__header {
    text-align: center;
  }
}
</style>
